// #region imports

import React from 'react';

// Material-UI
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField'

// #endregion

// #region interfaces

interface GenderSelectProps {
  gender: string;
  genderSelected: (gender: string) => void;
}

// #endregion

const GenderSelect: React.FC<GenderSelectProps> = (props) => {

  // #region Styles

  const styles = {
    selectWidth: {
      flexGrow: 1,
      minWidth: 120,
    },
  };

  // #endregion

  // #region Event handlers

  const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.genderSelected(event.target.value);
  };

  // #endregion

  return (
    <TextField
      sx={styles.selectWidth}
      label="Gender"
      onChange={handleGenderChange}
      select
      value={props.gender}
    >
      <MenuItem key={'f'} value={'F'}>Female</MenuItem>
      <MenuItem key={'m'} value={'M'}>Male</MenuItem>
    </TextField>
  );

}

export default GenderSelect;